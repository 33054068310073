
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/8ffb4ac4079be0ed/cookie-consent-ccpa-link/src/components/DoNotSellMyData/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  var stylesParams = null;

  var sentryConfig = {
      DSN: 'https://024cf4e66e8e4e94a5405f2c4db72d31@sentry.wixpress.com/388',
      id: '024cf4e66e8e4e94a5405f2c4db72d31',
      projectName: 'cookie-consent-ccpa-link',
      teamName: 'cookie-consent',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'DoNotSellMyData'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  export default {
    component: UserComponent
  };
